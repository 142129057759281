<template>
  <div>
    <Divider
      dashed
      style="color:#fff"
    >素材上传概览</Divider>
    <Timeline class="p-l-10 p-r-10">
      <TimelineItem
        :color="item.status===5?'#19be6b':item.status===6||item.status===2?'#ed4014':'orange'"
        :class="item.id===approval.id?'text-green':''"
        v-for="(item,index) in showApprovalList"
        :key="index"
      >
        <Icon
          :type="item.status===5?'md-checkmark-circle':item.status===6?'md-close-circle':item.status===2?'md-remove-circle':'md-time'"
          slot="dot"
        ></Icon>
        <p class="time">{{item.createTime}}</p>
        <Tag
          v-if="item.supply === 1"
          color="blue"
        > 待补充</Tag>
        <p class="content">{{item.title}}(<a @click="changeScreenhistory(item)">查看详情</a> )</p>
      </TimelineItem>
      <TimelineItem v-show="showApprovalList.length<approvalList.length"><a @click="handleViewMore">{{approvalList.length>10?'查看2更多':''}}</a></TimelineItem>
    </Timeline>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pageNumber: 1,
      pageSize: 10,
      showApprovalList: []
      // orderId: this.$store.state.installSetting.demandOrderId
    }
  },
  computed: {
    approvalList: {
      get () {
        return this.$store.state.task.approvalFileList
      },
      set (val) {
        this.$store.commit('set_approval_file_list', val)
      }

    },
    approval: {
      get () {
        return this.$store.state.task.approvalFile
      },
      set (val) {
        this.$store.commit('set_approval_file', val)
      }
    }
  },
  mounted () {
    this.getApprovalHistory()
  },
  destroyed () {
    this.approvalList = []
    this.approval = {}
  },
  methods: {
    getApprovalHistory () {
      this.$store.dispatch('getApprovalFileHistory')
    },
    changeScreenhistory (history) {
      this.approval = history
      this.$store.commit('set_material_manage_tab', 'history')
    },
    handleViewMore () {
      this.pageNumber++
      this.showApprovalList = this.approvalList.slice(0, this.pageNumber * this.pageSize - 1)
    }
  },
  watch: {
    approvalList: {
      deep: true,
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal && newVal.length) {
          this.showApprovalList = newVal.slice(0, this.pageSize - 1)
          this.approval = this.approval.id ? newVal.find(x => x.id === this.approval.id) : newVal[0]
        }
      }
    }
  }
}
</script>
